$ruler: 16px;
$color-base: #282728;
$color-bg: #32373c;
$color-shadow: #32373c;
$color-white: #ffffff;
$color-point: #2e789a;
$color-light: #c7eaf9;
$color-pin: #757b81;
$color-body: #EBECF0;

.shdow{
  color:$color-white;
  filter: drop-shadow(1px 1px 1px $color-shadow);
}

.shdow_add{
  color:$color-white;
  filter: drop-shadow(-2px -2px 3px $color-shadow);
  
}
.shdow_in{
 
  filter: drop-shadow(1px -1px 1px $color-white);
  
}

.shdow_upload{
  color:$color-white;
  filter: drop-shadow(1px 1px 1px $color-shadow);
  margin: 10px;
}
select{
  padding:10px;
  margin: 20px 0px;
  width:450px;
  height:40px;
  
  border-radius: 15px;
  cursor: pointer;

  text-overflow: ellipsis;
  border: none;
  outline: none;
  background: $color-body;
  background-image: none;
  -webkit-appearance: none;
  appearance: none;

  background-image: url(../img/arrow2.png);
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 26px 26px;
  
  //box-shadow: 4px 4px 2px #FFF inset,  6px 6px 6px $color-shadow ; 
  box-shadow:  inset 2px 2px 3px $color-shadow, inset -3px -3px 5px $color-white;
  
}
select::-ms-expand {
  display: none; /* デフォルトの矢印を無効(IE用) */
}

.btn_nomal {
    border: 0;
    outline: 0;
    font-size: $ruler;
    border-radius: 30px;
    padding: $ruler;
    background-color: $color-point;
    text-shadow: 1px 1px 0 $color-shadow;
    margin: 0 auto;
    color:$color-white;
    font-weight:900;
    box-shadow: -1px -1px 1px $color-light,  5px 5px 10px $color-shadow;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
   
    
    &:hover {
      box-shadow: -2px -2px 5px $color-light, 2px 2px 5px $color-shadow;
    }
    
    &:active {
      box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-light;
    }
    
    .icon {
      margin-right: $ruler/2;
    }
    
    &.unit {
      border-radius: $ruler/2;
      line-height: 0;
      width: $ruler*3;
      height: $ruler*3;
      display:inline-flex;
      justify-content: center;
      align-items:center;
      margin: 0 $ruler/2;
      font-size: $ruler*1.2;
      
      .icon {
        margin-right: 0; 
      }
    }
}
.btn_blue {
  border: 0;
  outline: 0;
  font-size: $ruler;
  border-radius: 20px;
  padding: $ruler;
  background-color: $color-point;
  text-shadow: 1px 1px 0 $color-white;
  margin: 0 auto;
  color:#61677C;
  font-weight:900;
  box-shadow: -1px -1px 1px $color-light,  5px 5px 20px $color-shadow;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

 
  
  &:hover {
    box-shadow: -2px -2px 5px $color-light, 2px 2px 5px $color-shadow;
  }
  
  &:active {
    box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-light;
  }
  
  .icon {
    margin-right: $ruler/2;
  }
  
  &.unit {
    border-radius: $ruler/2;
    line-height: 0;
    width: $ruler*3;
    height: $ruler*3;
    display:inline-flex;
    justify-content: center;
    align-items:center;
    margin: 0 $ruler/2;
    font-size: $ruler*1.2;
    
    .icon {
      margin-right: 0; 
    }
  }
}




#maru_btn{
    position: absolute;
    left:calc(100% - 40px);
    top:-20px;
    width:30px;
    height:30px;
    border-radius: 50%;
    display:flex;
    justify-content: center;
    align-items:center;
  }

  

  #maru_btn_mini{
    position: absolute;
    left:calc(100% - 70px);
    top:10px;
    width:30px;
    height:30px;
    border-radius: 50%;
    display:flex;
    justify-content: center;
    align-items:center;
  }

  

#_btn{
    display:flex;
    justify-content: center;
    align-items:center;
    width:180px;
    height:20px;
    margin: 10px;
}

#mini_btn{
  display:flex;
    justify-content: center;
    align-items:center;
    width:120px;
    height:20px;
    margin: 10px;
}
#send_btn{
  position: absolute;
  left:calc(50% - 50px);
  bottom: - 20px;
  
  width:100px;
  height:100px;
  border-radius: 50%;
  margin: 0 auto;
  padding: 0;
  display:flex;
  justify-content: center;
  align-items:center;

}
#send_btn_file{
  position: absolute;
  left:calc(50% - 50px);
  bottom: - 50px;
  
  width:100px;
  height:100px;
  border-radius: 50%;
  margin: 0 auto;
  padding: 0;
  display:flex;
  justify-content: center;
  align-items:center;

}

.loading_icon {
  animation: App-logo-spin infinite 5s linear;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}