$ruler: 16px;
$color-blue: #1384bf;
$color-bg: #EBECF0;
$color-shadow: #BABECC;
$color-white: #FFF;

html{
    height:100%;
    width:100%;
    overflow: hidden  !important;
}
body{
  margin: 0;
  width:100%;
  height:100%;
 
  background-color: #e7effa;

}
#root{

  width:100%;
  height:100%;

}
audio{
  margin-top: 30px;
}
#preSound{
  display:none;
}
.visible{
  display:flex;
}
.invisible{
  display:none;
}
.urlStr{
  margin-top: 20px;
}

.sub_menu{
  position: absolute;
  width:300px;

  right:50px;
  top:50px;
  z-index: 40;
  background-color: #fff;
  border-radius: 20px;
  box-shadow:  5px 5px 15px rgb(134, 134, 134);
  padding:5px;
  .list{
    display:flex;
    margin: 10px;
    align-items: center;
    border-radius: 10px;
    background-color: #FFF;
    cursor: pointer;
    &:hover {
      background-color: #CCC;
    }
    
    &:active {
      background-color: #AAA;
    }

    p{
      width:200px;
      color:#000;
      text-shadow: none;
    }

    .maru{
      display:flex;
      margin-left: 10px;
      margin-right: 5px;
    justify-content: center;
    align-items: center;
  
      background-color: #1384bf;
      width:40px;
      height:40px;
      border-radius: 50%;
    }

  }

  .list_off{
    display:flex;
    margin: 10px;
    align-items: center;
    border-radius: 10px;
    background-color: #FFF;
    
    p{
      width:200px;
      color:rgb(166, 166, 166);
      text-shadow: none;
    }

    .maru{
      display:flex;
      margin-left: 10px;
      margin-right: 5px;
    justify-content: center;
    align-items: center;
  
      background-color: #1383bf54;
      width:40px;
      height:40px;
      border-radius: 50%;
    }

  }

  
}
.app_bg{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  background-color: #fff;
  width:640px;
  padding: 40px;
  padding-bottom: 100px;
  border-radius: 20px;
  box-shadow:  5px 5px 15px rgb(134, 134, 134);
  .app_title{
    margin-bottom: 20px;
    color: #000;
    font-weight: bold;
    size: 18px;
  }
  #input{
    width:520px;
  }
}
.app_select{
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 20px;
 width:600px;
 flex-wrap:wrap;
}
.app_select_size{
  width:250px;
  margin: 10px;
}

#canvas{
  display:none;
}
#selfile{
  display: none;
}
.preview_bg{
  position: fixed;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.8);
  z-index:100;
  .cal_form{
    display:flex;
    align-items: center;
    position: relative;

    width:70%;
    margin: 10px;
    
    #btn{
      
      
      width:150px;
      height:40px;
      padding: 0;
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        margin: 3px;
        color: #fff;
        text-shadow: none;
      }
    }
    #cal_day{
      width:180px;
      padding:10px;
      margin: 5px;
    }
    #cal_time{
      width:180px;
  
      padding:10px;
      margin: 5px;
    }
  }
  .preview_box{
   
    width:70%;
    height:70%;
    background-color: #000;
    
    overflow: hidden;
    position: relative;
    
    #preview_video,.preview_img{
      position: absolute;
      width: auto;
      height: auto;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    
  }
  
}



.group_box{
  margin: 0 auto;
  width:95%;
  display: flex;
  justify-content: center;
  align-items: center;
  .groupName{
    margin-left:0;
    margin-top: 20px;
    margin-bottom: 20px;
    height:40px;
  }
}
.cal_box{
  position: relative;
  background-color: #fff;
  width:1000px;
  height:90%;
  border-radius: 20px;
  box-shadow:  5px 5px 15px rgb(0, 0, 0);
  .main{
    height:calc(100% - 80px);
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
   
    p{
      color: #000;
      text-shadow:none;
      margin: 0;
      font-weight: bold;
    }
  .stb_tag{
    display:flex;
    align-items: center;

    margin: 10px;
    margin-bottom:0px;
    .timeline_id{
      width:70px;
      display:flex;
      align-items: center;
      justify-content: center;
    }
    .name{
   
      width:250px;
      text-align: center;
     
    }
    .stants{
      margin-left: 20px;
      width:100px;
      text-align: center;
    }

    .watch_time{
      margin-left: 10px;
      width:150px;
      text-align: center;
    }
    .command{
      margin-left: 0px;
      width:300px;
      text-align: center;
    }

  }

  .stb_block{
    display:flex;
    align-items: center;
    border-radius: 25px;
    border: 2px solid #AAA;
    margin: 10px;
    height:50px;
    padding: 10px;
    background-color: #CCC;
  .timeline_id{
    display:flex;
    align-items: center;
    justify-content: center;
    width:50px;
    height:50px;
    border-radius: 25px;
    background-color: #1384bf;
    color: #fff;
  
  }
  .name{
    margin:10px;
    width:250px;
    
  }
  .stants{
    width:100px;
    color: #ff4800;
  }
  .watch_time{
    width:150px;
    font-weight: normal;
    text-align: center;
  }
  .command{
    width:220px;
    margin-left: 20px;
    
  }
}
    
    .Calendar_block{
      width:100%;
      height:100%;
      overflow-y: scroll;
      overflow-x:hidden;
      scroll-margin: 150px;
      //background-color: #1384bf;
      .day_block{
        width:calc(100% - 40px);
        
        //background-color: #BABECC;
       
        padding: 20px;
        p{
          color: #000;
          font-weight:normal;
          margin: 0;

          letter-spacing: 2px;
          margin-left: 5px;
          margin-bottom: 2px;

        }
        .day_main{
          width:100%;
          height:120px;
          
          background-color: #6983a0;
          border-radius:10px;
          box-shadow: 3px 3px 5px #373737 inset;

        }
        .day_scroll{
          position: relative;
          width:100%;
          height:140px;
          overflow-x: scroll;
          overflow-y: hidden;
          
          display: flex;
         

         
         
          .day_item{
            flex: none;
            margin: 5px;
            margin-top: 10px;
            width:100px;
            height:100px;
            background-color: #FFF;
            display:flex;
            position: relative;
            align-items: center;
            justify-content: center;
            img{
              max-width: 90px;
              max-height: 90px;
            }
            p{
              position: absolute;
              letter-spacing: 0px;
              top: 85px;
              color:#000;
              margin: 0 auto;
              font-size: 11px;
              //改行しない
              height: 20px;
              
              width: 90px; /* コンテナの幅を設定 */
              overflow: hidden; /* コンテナの外にテキストが溢れた場合に非表示にする */
              white-space: nowrap; /* テキストを一行にする */
              text-overflow: ellipsis; /* テキストが溢れるときに省略記号を表示 */

            }
          }
        }
      

      }

    }
    
  }
  .footer{
    display:flex;
    align-items: center;
    justify-content: center;

    height:100px;

    
  }
}
#command_btn{
  text-shadow: none;
  color: #fff;
  height:20px;
  width: 50px;
  border-radius: 15px;
  margin: 5px;
  padding: 10px;
  text-align: center;

}

.black_bg{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 200;
  width:100%;
  height:100%;
  background-color:#2e2e2ee4;

  .item_box{
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: #FFF;
    width:900px;
    height:550px;
    
    border-radius: 20px;
    box-shadow:  5px 5px 15px rgb(0, 0, 0);
  }

  .white_bg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    position: relative;
    border-radius: 20px;
    padding: 50px;
    box-shadow:  5px 5px 15px rgb(0, 0, 0);
    width:500px;

    .option{
      font-weight: bold;
      color: #307898;
      cursor: pointer;
    }
    .item_option{
      display:flex;
      flex-direction: column;
      #item_box{
        display:flex;
        margin-bottom: 10px;
        align-items: center;
        text-align: center;  
        p{
          color:#333;
          margin: 10px;
          text-shadow:none;
        }
        #input{
          width:160px;
        }
        #radio{
          width:20px;
          margin-left: 0px;
          accent-color:#307898;
        }
        label{
          display: flex;
          text-align: left;
          justify-content: center;
          align-items: center;
          p{
            margin-right: 30px;
          }
       
        }
      }
      
    }
  }
  input{
    width:450px;
    height:30px;
  }
  .thum,.thum_mov{
    margin: 20px;
    height: 180px;
    
  }
  .timeBox{
    display: flex;
    justify-content: center;
    align-items: center;
    #input{
      width:70px;
      height:30px;
    }
    p{
      color: #000;
      margin-left: 10px;
      font-weight: bold;
    }


  }

  .pre_btn{
   
      color: #FFF;
      text-shadow:none;
      font-weight: bold;
 
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
        align-items: center;
        border-radius:20px;
        width:200px;
        height:40px;

        background-color:#31789b;  
        box-shadow: 2px 2px 1px #FFF inset,  6px 6px 15px #6d7378;

        transition: all 0.2s ease-in-out;
        cursor: pointer;
       
        
        &:hover {
          box-shadow: 2px 2px 1px #FFF inset, 0px 0px 0px #6d7378;
          background-color:#4591b7;
        }
        
        &:active {
          box-shadow: 2px 2px 1px #FFF inset, inset -1px -1px 2px $color-white;
          background-color:#59a5ca;
        }
        
    }
  }
  .esc{
    margin-top: 40px;
    width:150px;
    height:40px;
    padding: 0;
  }
  .btn_set{
    display:flex;
    margin: 20px;
    .esc{
      margin: 0;
    }
    .pre_btn{
      width:150px;
    height:40px;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
    }

  }
  .dd_box{
    position: relative;
    width:600px;
    height:300px;
    border-radius:10px;
    background-color: #fff;
    box-shadow:  10px 10px 20px rgb(114, 114, 114);
    padding: 10px;
    .dd_area,.dd_area_on{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width:100%;
      height:100%;
      border-radius:10px;
      background-color: #e7effaba;
      box-shadow:  inset 5px 5px 15px rgb(134, 134, 134);
      p{
        font-weight: bold;
      }
      .file_btn{
        p{
          color: #FFF;
          text-shadow:none;

        }
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
            align-items: center;
            border-radius:20px;
            width:200px;
            height:40px;
    
            background-color:#31789b;  
            box-shadow: 2px 2px 1px #FFF inset,  6px 6px 15px #6d7378;
    
            transition: all 0.2s ease-in-out;
            cursor: pointer;
           
            
            &:hover {
              box-shadow: 2px 2px 1px #FFF inset, 0px 0px 0px #6d7378;
              background-color:#4591b7;
            }
            
            &:active {
              box-shadow: 2px 2px 1px #FFF inset, inset -1px -1px 2px $color-white;
              background-color:#59a5ca;
            }
            
      }
 
    
    }
    .dd_area_on{
      background-color: #FFF;
    }
  }

.delBox{
  display: flex;
  flex-direction: column;

  
  

  z-index: 6;
  position: relative;
  .delArea{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border-radius:50%;
    width:100px;
    height:100px;
    
    top:-45px;
    padding: 8px;
    background-color: #FFF;
    box-shadow: 5px 5px 10px #acb5bc;
    .delCircle{
      width:100%;
      height:100%;
      border-radius:50%;
      background-color:#31789b;
      box-shadow:  inset 2px 2px 15px rgba(0, 0, 0, 0.448);
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width:40%;
      }

    }

  }
}

.timeline{
  display: flex;
  flex-direction: column;
  position: relative;
  
  background-color: #cbd8e8;
  height:250px;
  box-shadow: 0px -5px 10px #acb5bc;
  .timeline_box{
    display: flex;
    margin: 0 auto;
    width:95%;
    
    .btn{
      display: flex;
      justify-content: center;
      align-items: center;
        margin: 23px 8px;
        border-radius:3px;
        width:35px;
        height:35px;
        background-color: #FFF;
        box-shadow: -1px -1px 1px $color-white,  5px 5px 10px #6d7378;

        transition: all 0.2s ease-in-out;
        cursor: pointer;
       
        &:hover {
          box-shadow: 0px 0px 2px $color-white, 2px 2px 5px #6d7378;
        }
        
        &:active {
          box-shadow: inset 1px 1px 2px #6d7378, inset -1px -1px 2px $color-white;
        }
    }
    .right{
      margin: 0 auto;
      display:flex;
      justify-content:flex-end;
      align-items: center;
      
      width:100%;
      height:80px;
      //background-color: #59a5ca;
      #timeline_status{
        display:flex;
        justify-content:center;
        align-items: center;

      }
      p{
        margin: 5px;
        font-size: 18px;
        color: #898989;
        font-weight: 900;
      }
      .net_status{
        color: rgb(231, 0, 0);
      }
      

      .send_btn{
        
        margin-left: 5px;
        margin-right: 0px;
        display: flex;
        justify-content: center;
            align-items: center;
 
            border-radius:20px;
            width:100px;
            height:65px;
    
            background-color:#31789b;  
            box-shadow: 2px 2px 1px #FFF inset,  6px 6px 15px #6d7378;
    
            transition: all 0.2s ease-in-out;
            cursor: pointer;
           
            
            &:hover {
              box-shadow: 2px 2px 1px #FFF inset, 0px 0px 0px #6d7378;
              background-color:#4591b7;
            }
            
            &:active {
              box-shadow: 2px 2px 1px #FFF inset, inset -1px -1px 2px $color-white;
              background-color:#59a5ca;
            }
            
      }
      
 
    }
   


  }
  .timeline_main{
    display: flex;
    margin: 0 auto;
    position: relative;
   
    width: 95%;
    height:126px;
    background-color: #6983a0;
    border-radius:10px;
    box-shadow: 3px 3px 5px #373737 inset;



  .timeline_scroll{
    display: flex;
    margin: 0 auto;
    width: 100%;
    height:150px;

    padding-left: 5px;
    padding-right: 5px;
    overflow-y: hidden;
    overflow-x: scroll;



  }
    
    
    .item_box{
      display:flex;
      flex-wrap: wrap;
      width:106px;
      height:106px;
      margin: 4px;
      margin-top:10px;
      background-color: #fff;
      border-radius:10px;
      box-shadow: -5px -5px 5px inset $color-white,  5px 5px 10px #46474b;
      
      

      //transition: all 0.2s ease-in-out;
          //cursor: pointer;
        
          
          &:hover {
            box-shadow: -5px -5px 5px inset $color-white,  2px 2px 5px #46474b;
          
          }
          
          &:active {
            box-shadow: 1px 1px 5px #46474b inset, inset -1px -1px 1px $color-white;

          }

        .img{
          width:20px;
          height:20px;
          margin: 5px;
        }

        .icon_bg{
          border-radius:0px 10px 0px 0px;
          margin-top: 3px;
          background-color: #e6eef7;
          width:72px;
          
          height: 26px;
          display: flex;
          align-items: center;

          img{
            margin: 0 auto;
          }
          .icon_set{
            margin-left: 4px;
            padding-top: 4px;
            width:64px;
            height: 26px;
          }

        }
        .thum_box{
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
      
          width:120px;
          height:60px;
          #thum{
            position: absolute;
            margin: 0 auto;
            max-width: 100px;
            max-height: 65px;
           
           
          }
          p{
            position: absolute;
            top: 52px;
            color:#000;
            margin: 0 auto;
            font-size: 11px;
            //改行しない
            height: 20px;
            
         
            width: 90px; /* コンテナの幅を設定 */
            overflow: hidden; /* コンテナの外にテキストが溢れた場合に非表示にする */
            white-space: nowrap; /* テキストを一行にする */
            text-overflow: ellipsis; /* テキストが溢れるときに省略記号を表示 */
          }
         

          .thum0,.thum1,.thum2{
            position: absolute;
            position: absolute;
            top: 60%;
            left: 40%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);

            max-width: 70px;
            max-height: 50px;
            box-shadow: 2px 2px 2px #46474b;
          }
          .thum0{
            z-index: 10;
          }
          .thum1{
            z-index: 9;
            top: 45%;
            left: 50%;
          }
          .thum2{
            z-index: 8;
            top: 30%;
            left: 60%;

          }
         
          .cover{
            z-index: 200;
          }
        }

      }
    

  }
 
}
#timeline_group{
  background-color:#DDD;
}
#timeline_nomal{
  background-color: #cbd8e8;
}
.item{
  
  margin: 0 auto;
  width:95%;
  //margin-top: 30px;
  padding-top: 70px;
  padding-bottom: 50px;
  height:calc(100% - 450px);
  overflow-y: scroll;
  overflow-x:hidden;
  scroll-margin-top: 250px;

  //background-color: #4591b7;
  .item_scroll{
    display: flex;
    align-items:flex-start;
    align-content:flex-start;
    flex-wrap: wrap;
    scroll-margin: 150px;
    p{
      width:150px;
      margin: 20px;
    }
    .item_box2{

      display:flex;
      flex-wrap: wrap;
      width:120px;
      height:120px;
      margin: 6px;
      background-color: #fff;
      border-radius:10px;
      box-shadow: -5px -5px 5px inset $color-white,  20px 20px 20px #9ea3a8;
      ~div {
        transform: none !important;
      }
  }   
     

    
      
    .item_box{
     
      display:flex;
      flex-wrap: wrap;
      width:120px;
      height:120px;
      margin: 6px;
      background-color: #fff;
      border-radius:10px;
      box-shadow: -5px -5px 5px inset $color-white,  20px 20px 20px #9ea3a8;


      //transition: all 0.2s ease-in-out;

          
          &:hover {
            box-shadow: -5px -5px 5px inset $color-white,  5px 5px 10px #9ea3a8;
          
          }
          
          &:active {
            box-shadow: 1px 1px 5px #9ea3a8 inset, inset -1px -1px 2px $color-white;

          }

          .img_type{
            width:20px;
            height:20px;
            margin: 4px;
          }
         

        .img{
          width:18px;
          height:18px;
          margin-top: 3px;
          
        }

        .icon_bg{
          border-radius:0px 10px 0px 0px;
          margin-top: 3px;
          padding-top: 3px;
          background-color: #e6eef7;
          width:88px;
          margin-left: 0px;
          height: 20px;
          display: flex;
          align-items: center;

         
          .setting_btn{
            width:20px;
            height:20px;
            margin-top: -5px;
            margin-right: 2px;
            cursor: pointer;
          }
          .icon_set{
            margin-left: 1px;
            width:80px;
            height: 26px;
          }

        }
        .thum_box{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: relative;
          width:120px;
          height:50px;
          p{
            position: absolute;
            top: 53px;
            color:#000;
            margin: 0 auto;
            font-size: 12px;
            //改行しない
            height: 20px;
            
         
            width: 100px; /* コンテナの幅を設定 */
            overflow: hidden; /* コンテナの外にテキストが溢れた場合に非表示にする */
            white-space: nowrap; /* テキストを一行にする */
            text-overflow: ellipsis; /* テキストが溢れるときに省略記号を表示 */

          }
          
         
          #thum{
            margin: 0 auto;
            z-index: 1;
            max-width: 110px;
            max-height: 85px;
            
          }
          .thum0,.thum1,.thum2{
            position: absolute;
            top: 60%;
            left: 40%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            
            
            max-width: 80px;
            max-height: 60px;
            box-shadow: 2px 2px 2px #46474b;
          }
          .thum0{
            z-index: 10;
            
          }
          .thum1{
            z-index: 9;
            top: 45%;
            left: 50%;
            
            
           
          }
          .thum2{
            z-index: 8;
            top: 30%;
            left: 60%;
            //transform:rotate(30deg); 

          }

        }

      }
  }

}
.app_box{

  margin: 0 auto;
  margin-top: 30px;
  width:800px;
  display:flex;
  justify-content: center;
  align-items: center;
 .app_area{
  display: flex;
  margin: 0 auto;
  position: relative;
  width: 800px;
  height:116px;
  background-color: #e7effaba;
  border-radius:10px;
  box-shadow: 3px 3px 5px #373737 inset;




   .app_scroll{
    width:100%;
    display:flex;
    height:146px;
    overflow-y: hidden;
    overflow-x: scroll;
    
    
   align-items: center;
   .app_main_invisible{
    display:none;
   }
   .app_main{
     width:70px;
     height:100%;
     display:flex;
     flex-direction: column;
     margin: 0px 25px;
     

     .app_icon{
      
        p{
          
          color: #FFF;
          text-shadow:none;

        }
        
        display: flex;
        justify-content: center;
        align-items: center;
            border-radius:10px;
            width:80px;
            height:80px;
            margin-top: 10px;
            background-color:#31789b;  
            box-shadow: 2px 2px 1px #FFF inset,  6px 6px 15px #6d7378;
    
            transition: all 0.2s ease-in-out;
            cursor: pointer;
           
            
            &:hover {
              box-shadow: 2px 2px 1px #FFF inset, 0px 0px 0px #6d7378;
              background-color:#4591b7;
            }
            
            &:active {
              box-shadow: 2px 2px 1px #FFF inset, inset -1px -1px 2px $color-white;
              background-color:#59a5ca;
            }
            
     
        img{
          width:50px;
          height:50px;
        }
  
       
     }
     .app_txt{
      width:80px;
       height:15px;
       
       p{
         text-align: center;
         font-size: 12px;
         margin: 0 auto;
         margin-top: 5px;
         color:#333;
         
       }
     }
    }
   }
 }

}
.navi{
  position:relative;
  width:100%;
  height:80px;
  background-color: #ffffff;
  box-shadow: 0px 5px 5px #acb5bc;
 
  display: flex;
  flex-direction: column;
  .search{
    margin: 0 auto;
    width:95%;
  .search_box{
    display: flex;
    justify-content: center;
    position:relative;
    margin-top:50px;
    width:400px;
    cursor: pointer;
   
    
    #input{
      position:absolute;
      width:400px;
      height:40px;
       

    }
    .search_btn{
      position:absolute;
      left:360px;
      top:6px;
    }
    .search_btn_set{
      position:absolute;
      width:500px;
      left:400px;
      top:-5px;
    }
    .btn{
      width:42px;
      height: 42px;
      border-radius: 5px;
      margin-left: 5px;
      padding: 0;
      background-color:#FFF;
      img{
        margin: 0 auto;
      }
    }
    .btn_off{
      width:42px;
      height: 42px;
      border-radius: 5px;
      margin-left: 5px;
      padding: 0;
      background-color:rgb(223, 223, 223);
      box-shadow: inset 2px 2px 2px $color-shadow, inset -2px -2px 2px $color-white;
      img{
        margin: 0 auto;
      }
    }

  }
  }
  
  .main_btn{
   
    margin: 0 auto;
    width:100%;
   
    

    
  
   

  }
  .main_btn_box{
    position:relative;
    margin: 0 auto;
    width:95%;
   
    
  }
  .button_bg{
    position:absolute;
    right:20px;
    top:30px;
    width:110px;
    height:85px;
    border-radius:0 0 55px 55px;
    background-color: #ffffff;
    box-shadow: 5px 5px 10px #acb5bc;
    z-index: 20;
  }
  .add_btn{
    z-index: 30;
    display: flex;
        justify-content: center;
        align-items: center;
        position:absolute;
        right:30px;
        top:8px;
        border-radius:48px;
        width:96px;
        height:96px;

        background-color:#31789b;
        //background-color:#f8882c;
        box-shadow: 4px 4px 2px #FFF inset,  6px 6px 8px $color-shadow; 

        transition: all 0.2s ease-in-out;
        cursor: pointer;
       
        
        &:hover {
          box-shadow: 4px 4px 2px #FFF inset, 0px 0px 0px $color-shadow;
          background-color:#4591b7;
        }
        
        &:active {
          box-shadow: 4px 4px 2px #FFF inset, inset -1px -1px 2px $color-white;
          background-color:#59a5ca;
        }
        
  }
  .header{
    display: flex;
    margin: 0 auto;
    width:95%;
    height:50px;
  
    .logo{
      position: absolute;
      top:20px;
      width:160px;
      
    }
    .right{
      width:100%;
      height:60px;
      
      display: flex;
      justify-content: flex-end;
      align-items:center;
      .userBG{
        display: flex;
        justify-content: center;
        align-items: center;
        width:28px;
        height:28px;
        border-radius: 50%;
        background-color: #1384bf;
      }
      p{
        margin: 0px 5px;
        color: #000;
      }
      .strage_bar_bg{
        position: relative;
        width:100px;
        height:12px;
        border-radius:10px;
        background-color:#8c9db4;
        box-shadow:  inset 0px 3px 3px #30373f;
        margin: 0 0 0 15px;
        
      }
      .strage_bar{
        position: absolute;
        width:10px;
        height:12px;
        border-radius:10px 0px 0px 10px;
        background-color:#ffdc51;
        box-shadow:  inset 0px 2px 2px #30373f;
      }
      .setting{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 20px;
        border-radius:3px;
        width:35px;
        height:35px;
        background-color: #FFF;
        box-shadow: -3px -3px 5px $color-white,  5px 5px 10px #acb5bc;
        padding: 0;

        transition: all 0.2s ease-in-out;
        cursor: pointer;
       
        &:hover {
          box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
        }
        
        &:active {
          box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
        }


        
      }
      
    }
  }
 
  
}

.login_container{
  display: flex;
  align-items: center;
  justify-content: center;

  height:100%;
  width:100%;
}

.login_box{
    width:620px;
    height:520px;
   
    border-radius: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;

    box-shadow: 2px 2px 5px #FFF inset,  15px 15px 120px #acb5bc;
    img{
        margin: 0 auto;
        margin-top: 10px;
        
    }
    p{
        margin-bottom: 40px;
        width: 100%;
        text-align: center;
        color: #1384bf;
        text-shadow: 1px 1px 1px #FFF;
    }
    #input{
      margin: 10px;
      width:320px;
    }
}
.login_set{
  display: flex;
  align-items: center;
  justify-content: center;
  width:80px;
  margin: 0 auto;
 
  #login{
      margin: 0 auto;
      text-align: center;
  }

}

.shdowLock{
  display: block;
  
  margin: 0 auto;
  
  filter: drop-shadow(1px 1px 1px #000);
}
.item_img{
  padding:5px;
}

.item_icon{
  margin:2px;
  
}
.blue_btn{
  p{
    color: #FFF;
    font-weight:900;
    text-shadow:none;

  }
  margin: 10px;

  display: flex;
  justify-content: center;
      align-items: center;
      border-radius:20px;
      width:200px;
      height:40px;

      background-color:#31789b;  
      box-shadow: 2px 2px 1px #FFF inset,  6px 6px 15px #6d7378;

      transition: all 0.2s ease-in-out;
      cursor: pointer;
     
      
      &:hover {
        box-shadow: 2px 2px 1px #FFF inset, 0px 0px 0px #6d7378;
        background-color:#4591b7;
      }
      
      &:active {
        box-shadow: 2px 2px 1px #FFF inset, inset -1px -1px 2px $color-white;
        background-color:#59a5ca;
      }
      
  }

  .white_btn{
  color:#61677C;
  font-weight:900;
  box-shadow: -2px -2px 3px $color-white,  3px 3px 3px #acb5bc;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin: 10px;
  
  display: flex;
  justify-content: center;
      align-items: center;
      border-radius:20px;
      width:200px;
      height:40px;
 
  
  &:hover {
    box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
  }
  
  &:active {
    box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
  }
  
  .icon {
    margin-right: $ruler/2;
  }
  
  &.unit {
    border-radius: $ruler/2;
    line-height: 0;
    width: $ruler*3;
    height: $ruler*3;
    display:inline-flex;
    justify-content: center;
    align-items:center;
    margin: 0 $ruler/2;
    font-size: $ruler*1.2;
    
    .icon {
      margin-right: 0; 
    }
  }
  
  &.red {
    display: block;
    width: 200px;
    margin-top: 30px;
    color:$color-blue;
  }

  }






body, html {
  background-color:$color-bg;
}

body, p, input, select, textarea, button {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: -0.2px;
    font-size: $ruler;
}

div, p {
  color: $color-shadow;
  text-shadow: 1px 1px 1px $color-white;
}


form {
  padding: $ruler;
  width: $ruler*20;
  margin: 0 auto;
}

.segment {
  padding: $ruler*2 0;
  text-align: center;
}

button, input {
  border: 0;
  outline: 0;
  font-size: $ruler;
  border-radius: 20px;
  padding: $ruler;
  background-color:$color-bg;
  text-shadow: 1px 1px 0 $color-white;
  margin: 0 auto;
}



#input {

  box-shadow:  inset 2px 2px 5px $color-shadow, inset -5px -5px 10px $color-white;
  width: 400px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
  
  

  &:focus {
    box-shadow:  inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
  }
}

button {
  color:#61677C;
  font-weight:900;
  box-shadow: -5px -5px 20px $color-white,  5px 5px 20px #acb5bc;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
 
  
  &:hover {
    box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
  }
  
  &:active {
    box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
  }
  
  .icon {
    margin-right: $ruler/2;
  }
  
  &.unit {
    border-radius: $ruler/2;
    line-height: 0;
    width: $ruler*3;
    height: $ruler*3;
    display:inline-flex;
    justify-content: center;
    align-items:center;
    margin: 0 $ruler/2;
    font-size: $ruler*1.2;
    
    .icon {
      margin-right: 0; 
    }
  }
  
  &.red {
    display: block;
    width: 200px;
    margin-top: 30px;
    color:$color-blue;
  }
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    margin: 0;
    flex: 1;
  }
}


div::-webkit-scrollbar {
  
  width: 12px;
  height: 12px;
}

div::-webkit-scrollbar-track {

  background: #FFF;
  border: none;
  border-radius: 10px;
  box-shadow: inset  2px 2px 2px #9ea3a8;

  margin-top: 10px;
  margin-bottom: 10px;

 

}

div::-webkit-scrollbar-thumb {
  
  background: #31789b;
  border-radius: 10px;
  box-shadow:  inset 1px 1px 2px #FFF, 2px 2px 4px #999;

  margin-top: 4px;
  margin-bottom: 4px;

 
  
  
}


ul::-webkit-scrollbar {
  
  width: 12px;
  height: 12px;
}

ul::-webkit-scrollbar-track {
  background: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #DDD;

  margin-top: 10px;
  margin-bottom: 10px;
}

ul::-webkit-scrollbar-thumb {

  background: #FFF;
  border-radius: 10px;
  box-shadow: none;
}


#under_area::-webkit-scrollbar-track,
#under_area2::-webkit-scrollbar-track,
#group_area::-webkit-scrollbar-track {
 
  background: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #DDD;
}

#under_area::-webkit-scrollbar-thumb,
#under_area2::-webkit-scrollbar-thumb,
#group_area::-webkit-scrollbar-thumb {

  background: #f7c662;
  border-radius: 10px;
  box-shadow: none;
}
#func_btn{
  
  padding: 8px;
  width:50px;

  margin-right:5px;
  
  
}
.center_box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: none;
  color: #fff;
  width:100%;
  height:100%;
  text-align: center;
 
  #btn{
    margin: 20px;
    width:150px;
    height:40px;
    padding: 0;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
      margin: 3px;
      color: #fff;
      text-shadow: none;
    }
  }
}
///////////////////////////////////////////////
.cal_form{
       
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #DDD;
  border-radius: 20px;
  display: flex;
  align-items:center;
  

  margin: 15px;
  margin-top: 30px;
  p{
    color: #000;
    text-shadow: none;
  }
  .timeline_name{
    width:280px;
    height:50px;
  }
}

.cal_day::-webkit-calendar-picker-indicator,.cal_time::-webkit-calendar-picker-indicator {
  background-color: #FFF;
  padding: 10px;
  border-radius: 10px;
  box-shadow:  5px 5px 3px $color-shadow;
  cursor:pointer;
  
}


.cal_day,.cal_time{
  box-shadow: inset 1px 1px 2px #000, inset -1px -1px 2px #FFF;

}

#cal_day1,#cal_day2,#cal_day3,#cal_day4{
  width:150px;
  height:20px;
 
  

}
#cal_time1,#cal_time2,#cal_time3,#cal_time4{
  width:100px;
  height:20px;
 

}

#setting{
height:50px;
padding: 0;
 color: #fff;
 text-shadow: none;
 width:100px;
}

#setting2{
  padding:0px;
  height:50px;
  width:90px;
}



.tab_box{
  width:970px;
  margin: 0 auto;
  height:calc(100% - 200px);
  overflow-y: scroll;
  overflow-x:hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  
  .bar{
    width:830px;
    
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .row{
   
    display: flex;
    margin-bottom: 40px;
  }
  .en{
    width:380px;
    margin: 0 auto;
  }

  
}

.heat_box{
  width:100%;

  position: relative;
  .heat_map{
    margin: 0 auto;
    width:800px;
    height:600px;
    margin-bottom: 50px;
    #heat_target,#grid{
      position: absolute;
     
    }
    #grid{
      mix-blend-mode: darken; 
      opacity: 0.8;
    }

  }
 
  
}
.toastBox{

 
  .block{
    opacity: 0;
    z-index: 1000;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding:5px 30px;
    bottom: 30px;
    background:rgba(0,0,0,0.75);
    border-radius: 10px;
    p{
      color: #FFF;
      text-shadow:none;
      margin: 5px;
    }
   
  }
  .toast_set{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}